import {
  LeaderboardSortBy,
  TTSLeaderboard,
  TTSOverviewStats,
  VCLeaderboard,
  VCOverviewStats,
} from '@/modules/slowpoke/model/stats.model'
import axios from 'axios'
import { slowpokeApiUrl } from '@/modules/slowpoke/api/_shared'
import { getAuthHeaders, getRequestIdHeaders } from '@/utils/fetch'
import { apiErrorCatcher } from '@/api/_shared'
import { timeRangeToQueryParams } from '@/modules/slowpoke/utils/stats'
import { StatsTimeRangeI } from '@/components/StatsTimeRange.vue'

export function getTTSOverviewStats(timeRange: StatsTimeRangeI): Promise<TTSOverviewStats> {
  const params = timeRangeToQueryParams(timeRange)

  return axios
    .get(slowpokeApiUrl(`admin/tts/stats/overview?${params.toString()}`), {
      headers: { ...getRequestIdHeaders(), ...getAuthHeaders() },
    })
    .catch(apiErrorCatcher)
    .then((r) => r.data)
}

export function getVCOverviewStats(timeRange: StatsTimeRangeI): Promise<VCOverviewStats> {
  const params = timeRangeToQueryParams(timeRange)

  return axios
    .get(slowpokeApiUrl(`admin/vc/stats/overview?${params.toString()}`), {
      headers: { ...getRequestIdHeaders(), ...getAuthHeaders() },
    })
    .catch(apiErrorCatcher)
    .then((r) => r.data)
}

export function getTTSLeaderboard(sortBy: LeaderboardSortBy): Promise<TTSLeaderboard> {
  return axios
    .get(slowpokeApiUrl(`admin/tts/stats/leaderboard?sortBy=${sortBy}`), {
      headers: { ...getRequestIdHeaders(), ...getAuthHeaders() },
    })
    .catch(apiErrorCatcher)
    .then((r) => r.data)
}

export function getVCLeaderboard(sortBy: LeaderboardSortBy): Promise<VCLeaderboard> {
  return axios
    .get(slowpokeApiUrl(`admin/vc/stats/leaderboard?sortBy=${sortBy}`), {
      headers: { ...getRequestIdHeaders(), ...getAuthHeaders() },
    })
    .catch(apiErrorCatcher)
    .then((r) => r.data)
}
