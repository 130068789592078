export type StatsType = 'tts' | 'vc'
export type StatsSubjectType = 'apiKey' | 'client' | 'totals'
export type StatsPeriod = 'day' | 'month'
export type StatsDateRange = { start?: Date; end?: Date }

export type PeriodicStats<T> = Record<string, T>
export type FormatStatsFn = (value: number) => string
export type ChartsDef<T> = [string, keyof T, FormatStatsFn?][]

export type CommonStats = {
  requests: number
  outputAudioLength: number
  outputAudioSize: number
}

export type TTSStats = CommonStats & {
  inputTextLength: number
  inputTextWordCount: number
}

export type TTSStatsPerPeriod = PeriodicStats<TTSStats>

export type TTSLeaderboardItem = TTSStats & {
  clientId: number
  email: string
}

export type TTSLeaderboard = TTSLeaderboardItem[]

export type TTSOverviewStats = {
  requests: number
  outputAudioLength: number
  avgReqPerClient: number
}

export type VCStats = CommonStats & {
  inputAudioLength: number
  inputAudioSize: number
}

export type VCStatsPerPeriod = PeriodicStats<VCStats>

export type VCLeaderboardItem = VCStats & {
  clientId: number
  email: string
}

export type VCLeaderboard = VCLeaderboardItem[]

export type VCOverviewStats = {
  requests: number
  outputAudioLength: number
  avgReqPerClient: number
}

export type SlowpokeAccountsStats = {
  createdAccounts: {
    daily: { [key: string]: number }
    total: number
  }
}

export enum LeaderboardSortBy {
  Requests = 'requests',
  OutputAudioLength = 'outputAudioLength',
}
