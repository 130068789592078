
import { defineComponent, PropType, reactive, watch } from 'vue'
import { TTSOverviewStats, VCOverviewStats } from '@/modules/slowpoke/model/stats.model'
import { errored, isLoaded, isLoading, loaded, LOADING_STATE, LoadingState } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { getTTSOverviewStats, getVCOverviewStats } from '@/modules/slowpoke/api/stats.api'
import { formatSecondsDuration } from '@/utils/time'
import { getExtendedDefaultDateRange } from '@/modules/slowpoke/utils/stats'
import StatsTimeRange, { StatsTimeRangeI } from '@/components/StatsTimeRange.vue'

export default defineComponent({
  name: 'SlowpokeAudioTotalSumStats',
  components: { StatsTimeRange, DataLoadingError, DataLoader },
  props: {
    type: {
      type: String as PropType<'vc' | 'tts'>,
      required: true,
    },
  },
  setup(props) {
    const data = reactive({
      stats: LOADING_STATE as LoadingState<TTSOverviewStats | VCOverviewStats>,
      timeRange: getExtendedDefaultDateRange(),
    })

    const fetchStats = async () => {
      try {
        data.stats = LOADING_STATE

        let fetchFn: (timeRange: StatsTimeRangeI) => Promise<VCOverviewStats> | Promise<TTSOverviewStats>

        switch (props.type) {
          case 'vc':
            fetchFn = getVCOverviewStats
            break
          case 'tts':
            fetchFn = getTTSOverviewStats
            break
          default:
            throw new Error('Unknown component type.')
        }

        data.stats = loaded(await fetchFn(data.timeRange))
      } catch (e) {
        data.stats = errored(e)
      }
    }

    watch(
      () => data.timeRange,
      () => fetchStats()
    )

    fetchStats()

    return {
      data,
      isLoaded,
      isLoading,
      formatSecondsDuration,
      fetchStats,
    }
  },
})
