import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SlowpokeAccountsStats = _resolveComponent("SlowpokeAccountsStats")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_SlowpokeAudioTotalSumStats = _resolveComponent("SlowpokeAudioTotalSumStats")!
  const _component_PeriodicStats = _resolveComponent("PeriodicStats")!
  const _component_SlowpokeUsageLeaderboard = _resolveComponent("SlowpokeUsageLeaderboard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Panel, { class: "panel" }, {
      header: _withCtx(() => [
        _createTextVNode("Accounts stats")
      ]),
      content: _withCtx(() => [
        _createVNode(_component_SlowpokeAccountsStats)
      ]),
      _: 1
    }),
    _createVNode(_component_Panel, { class: "panel" }, {
      header: _withCtx(() => [
        _createTextVNode("TTS overview statistics")
      ]),
      content: _withCtx(() => [
        _createVNode(_component_SlowpokeAudioTotalSumStats, { type: "tts" })
      ]),
      _: 1
    }),
    _createVNode(_component_Panel, { class: "panel" }, {
      header: _withCtx(() => [
        _createTextVNode("TTS usage statistics")
      ]),
      content: _withCtx(() => [
        _createVNode(_component_PeriodicStats, {
          "subject-type": "totals",
          type: "tts"
        })
      ]),
      _: 1
    }),
    _createVNode(_component_Panel, { class: "panel" }, {
      header: _withCtx(() => [
        _createTextVNode("TTS usage leaderboard")
      ]),
      content: _withCtx(() => [
        _createVNode(_component_SlowpokeUsageLeaderboard, { type: "tts" })
      ]),
      _: 1
    }),
    _createVNode(_component_Panel, { class: "panel" }, {
      header: _withCtx(() => [
        _createTextVNode("VC overview statistics")
      ]),
      content: _withCtx(() => [
        _createVNode(_component_SlowpokeAudioTotalSumStats, { type: "vc" })
      ]),
      _: 1
    }),
    _createVNode(_component_Panel, { class: "panel" }, {
      header: _withCtx(() => [
        _createTextVNode("VC usage statistics")
      ]),
      content: _withCtx(() => [
        _createVNode(_component_PeriodicStats, {
          "subject-type": "totals",
          type: "vc"
        })
      ]),
      _: 1
    }),
    _createVNode(_component_Panel, { class: "panel" }, {
      header: _withCtx(() => [
        _createTextVNode("VC usage leaderboard")
      ]),
      content: _withCtx(() => [
        _createVNode(_component_SlowpokeUsageLeaderboard, { type: "vc" })
      ]),
      _: 1
    })
  ], 64))
}