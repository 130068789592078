
import { defineComponent } from 'vue'
import Panel from '@/components/UI/Panel.vue'
import PeriodicStats from '@/modules/slowpoke/components/stats/PeriodicStats.vue'
import SlowpokeAccountsStats from '@/modules/slowpoke/components/stats/AccountsStats.vue'
import SlowpokeAudioTotalSumStats from '@/modules/slowpoke/components/stats/AudioTotalSumStats.vue'
import SlowpokeUsageLeaderboard from '@/modules/slowpoke/components/stats/UsageLeaderboard.vue'

export default defineComponent({
  name: 'SlowpokeStatistics',
  components: { SlowpokeUsageLeaderboard, SlowpokeAudioTotalSumStats, SlowpokeAccountsStats, PeriodicStats, Panel },
})
