
import { computed, defineComponent, reactive, watch } from 'vue'
import { ChartsDef, TTSStatsPerPeriod, PeriodicStats } from '@/modules/slowpoke/model/stats.model'
import { getExtendedDefaultDateRange, statsToChartsData } from '@/modules/slowpoke/utils/stats'
import ChartsGrid from '@/components/UI/ChartsGrid.vue'
import { getAccountsStats } from '@/modules/slowpoke/api/clients.api'
import { errored, isLoaded, isLoading, loaded, LOADING_STATE, LoadingState } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import StatsTimeRange from '@/components/StatsTimeRange.vue'

type AccountsStats = {
  periodic: PeriodicStats<{ accountsCreated: number }>
  totals: {
    accountsCreated: number
  }
}

export default defineComponent({
  name: 'SlowpokeAccountStats',
  components: { StatsTimeRange, DataLoadingError, DataLoader, ChartsGrid },
  setup() {
    const data = reactive({
      accountsStats: LOADING_STATE as LoadingState<AccountsStats>,
      timeRange: getExtendedDefaultDateRange(),
    })

    const chartsDef: ChartsDef<TTSStatsPerPeriod> = [['Created accounts', 'accountsCreated']]

    const fetchStats = async () => {
      try {
        data.accountsStats = LOADING_STATE

        const result = await getAccountsStats(data.timeRange)
        const accountsStats: AccountsStats = {
          periodic: {},
          totals: { accountsCreated: result.createdAccounts.total },
        }

        for (const date in result.createdAccounts.daily) {
          accountsStats.periodic[date] = { accountsCreated: result.createdAccounts.daily[date] }
        }

        data.accountsStats = loaded(accountsStats)
      } catch (e) {
        data.accountsStats = errored(e)
      }
    }

    const charts = computed(() => {
      if (!isLoaded(data.accountsStats)) {
        return null
      }

      return statsToChartsData(chartsDef, data.accountsStats.data.periodic)
    })

    watch(
      () => data.timeRange,
      () => {
        fetchStats()
      }
    )

    fetchStats()

    return {
      data,
      charts,
      isLoaded,
      isLoading,
      fetchStats,
    }
  },
})
