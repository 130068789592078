
import { defineComponent, PropType, reactive, watch } from 'vue'
import { LeaderboardSortBy, TTSLeaderboard, VCLeaderboard } from '@/modules/slowpoke/model/stats.model'
import { errored, isLoaded, isLoading, loaded, LOADING_STATE, LoadingState } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { getTTSLeaderboard, getVCLeaderboard } from '@/modules/slowpoke/api/stats.api'
import { formatSecondsDuration } from '@/utils/time'
import { SlowpokeRoute } from '@/modules/slowpoke/routes'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'SlowpokeUsageLeaderboard',
  components: { DataLoadingError, DataLoader },
  props: {
    type: {
      type: String as PropType<'vc' | 'tts'>,
      required: true,
    },
  },
  setup(props) {
    const data = reactive({
      stats: LOADING_STATE as LoadingState<TTSLeaderboard | VCLeaderboard>,
      sortBy: null as null | LeaderboardSortBy,
    })

    const router = useRouter()

    watch(
      () => data.sortBy,
      () => {
        if (data.sortBy) {
          fetchStats(data.sortBy)
        }
      }
    )

    const fetchStats = async (sortBy: LeaderboardSortBy) => {
      try {
        data.stats = LOADING_STATE

        let fetchFn: (soryBy: LeaderboardSortBy) => Promise<TTSLeaderboard> | Promise<VCLeaderboard>

        switch (props.type) {
          case 'vc':
            fetchFn = getVCLeaderboard
            break
          case 'tts':
            fetchFn = getTTSLeaderboard
            break
          default:
            throw new Error('Unknown component type.')
        }

        data.stats = loaded(await fetchFn(sortBy))
      } catch (e) {
        data.stats = errored(e)
      }
    }

    const navigateToClient = (id: string) => {
      router.push({ name: SlowpokeRoute.EditClient, params: { id } })
    }

    return {
      data,
      isLoaded,
      isLoading,
      formatSecondsDuration,
      LeaderboardSortBy,
      navigateToClient,
    }
  },
})
